import * as React from 'react';
import styles from './Sprints.module.scss';
import { Link } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import Avatar from '~/components/User/Avatar';
import { prepareTimeFromMinutes } from '~/utils/utils';
import { LOADERS, LOADERS_TYPE } from '~/const';
import Circle from '~/components/Loader/Circle/Circle';
import { useAppSelector } from '~/store';
import { selectLoader } from '~/store/slices/app/slice';
import { selectTasks } from '~/store/slices/task/slice';
import { selectUsers } from '~/store/slices/user/slice';
import plural from 'plural-ru';

interface IExecutiveTime {
    user: IUser;
    difficulty: number;
    count: number;
}

interface IFC {
    sprintId?: number;
    openStatuses?: number[];
    tasksIds: number[];
}

const SprintTimes = ({ sprintId, openStatuses, tasksIds }: IFC) => {
    const isSprintTasksListLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.SPRINT_TASKS_LIST, LOADERS_TYPE.LOADING),
    );
    const tasks = useAppSelector((state) => selectTasks(state));
    const users = useAppSelector((state) => selectUsers(state));
    let executiveTime: IExecutiveTime[] = [];

    const array: IExecutiveTime[] = [];
    let totalSprintTime = 0;
    let totalTaskCount = 0;
    tasksIds.forEach((id) => {
        const task = tasks[id];

        if (!task?.status) return;

        if (!openStatuses?.includes(task.status)) return;

        const executive = task.executive ? users[task.executive] : null;

        if (!executive) return;

        const taskTimeLeft = task.has_subtasks
            ? 15
            : task.timeLeft !== undefined
            ? task.timeLeft > 0
                ? task.timeLeft / 60
                : 15
            : 0;

        totalSprintTime += taskTimeLeft;
        totalTaskCount += task.has_subtasks ? 0 : 1;

        array[executive.id] = {
            user: executive,
            difficulty: (array[executive.id]?.difficulty || 0) + taskTimeLeft,
            count: (array[executive.id]?.count || 0) + (task.has_subtasks ? 0 : 1),
        };
    });
    executiveTime = array.sort((a, b) => b.difficulty - a.difficulty);

    return (
        <div className={styles.times}>
            {sprintId && isSprintTasksListLoading ? (
                <Circle className={styles.loader} />
            ) : (
                <>
                    {executiveTime.map((data, key) => (
                        <div className={styles.timeLine} key={key}>
                            <Link to={`${urlsMap.userList}/${data.user.id}`}>
                                <Avatar
                                    avatar={{ avatar: data.user.avatar, name: data.user.name }}
                                />
                                <span className={styles.name}>{data.user.name}</span>
                                {!!data.count && (
                                    <span className={styles.count}>{`${data.count} ${plural(
                                        data.count,
                                        'задача',
                                        'задачи',
                                        'задач',
                                    )}`}</span>
                                )}
                            </Link>
                            <span className={styles.difficulty}>
                                {prepareTimeFromMinutes(data.difficulty)}
                            </span>
                        </div>
                    ))}
                    <div className={styles.timeLineTotal}>
                        <div>
                            {!!totalTaskCount && (
                                <span className={styles.count}>{`${totalTaskCount} ${plural(
                                    totalTaskCount,
                                    'задача',
                                    'задачи',
                                    'задач',
                                )}`}</span>
                            )}
                        </div>
                        <span className={styles.difficulty}>
                            {prepareTimeFromMinutes(totalSprintTime)}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default SprintTimes;
