import store, { useAppSelector } from '~/store';
import { getDictionaries } from '~/store/slices/app/reducers';
import { getUserList } from '~/store/slices/userList/reducers';
import { selectUserListOptions } from '~/store/slices/userList/slice';
import { selectProjectListOptions } from '~/store/slices/projectList/slice';
import { getProjectList } from '~/store/slices/projectList/reducers';

export const prepareEntities = async (groupId?: number) => {
    // TODO: сейчас запросы кидаются последовательно, нужно сделать параллельные одновременные запросы (те, которые не зависят от предыдущих)
    store.dispatch(getDictionaries(groupId));
    // const projectListOptions = useAppSelector((state) => selectProjectListOptions(state));
    // store.dispatch(getProjectList({ offset: 0, options: projectListOptions }));
    //
    // const userListOptions = useAppSelector((state) => selectUserListOptions(state));
    // store.dispatch(getUserList({ offset: 0, options: userListOptions }));
};
