import { Centrifuge } from 'centrifuge';
import { SOCKET_MODEL, META } from '~/const';
import store from '~/store';
import { update as updateTask } from '~/store/slices/task/slice';
import { update as updateTiming } from '~/store/slices/timings/slice';
import { add as addNotification } from '~/store/slices/notification/slice';
import { increaseTotalCount } from '~/store/slices/notification/slice';
import { getTaskList } from '~/store/slices/taskList/reducers';
import { TASKS_FILTERS } from '~/utils/tasks';

export const socket = (accountId: number) => {
    const centrifuge = new Centrifuge(`${process.env.REACT_APP_WS_HOST}`, {
        token: localStorage.getItem('socket'),
    });
    const reduxStore = store.getState();
    const refreshList = () => {
        const groupId = reduxStore.app.meta.currentGroup;
        let options = { ...reduxStore.taskList.options };
        const pathName = window?.location.pathname.split('/');
        let projectId: number | undefined;
        if (pathName[1] === 'projects' && pathName[2]) {
            projectId = Number(pathName[2]);
        }

        options = projectId
            ? {
                  ...options,
                  filters: {
                      ...options.filters,
                      [TASKS_FILTERS.PID]: projectId || '',
                      [TASKS_FILTERS.STATUS]: 'open',
                  },
              }
            : options;

        store.dispatch(getTaskList({ options, offset: 0, groupId, isSilent: true }));
    };
    let currentTimeout: NodeJS.Timeout;
    const debounceRefreshList = () => {
        clearTimeout(currentTimeout);
        currentTimeout = setTimeout(() => {
            refreshList();
        }, 30000);
    };

    const sub = centrifuge.newSubscription(`channel_${accountId}`);

    sub.on('publication', (ctx) => {
        const data = ctx.data.data;
        switch (ctx.data.model) {
            case SOCKET_MODEL.TASK:
                store.dispatch(updateTask([data]));

                //const taskListIds = reduxStore.taskList.loadedIds;
                //if (taskListIds.includes(data.id)) {
                debounceRefreshList();
                //}

                break;
            case SOCKET_MODEL.TIMING:
                store.dispatch(updateTiming([data]));
                break;
            case SOCKET_MODEL.NOTIFICATION:
                store.dispatch(increaseTotalCount(1));
                store.dispatch(addNotification([data]));
                break;
            default:
                break;
        }
    });

    sub.subscribe();

    centrifuge.connect();
};
