import * as React from 'react';
import styles from './DatePicker.module.scss';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDebounce, useIsFirstRender } from 'usehooks-ts';
import { ReactComponent as Calendar } from '~/assets/icons/calendar.svg';
import moment from 'moment';
import ru from 'date-fns/locale/ru';
import classNames from 'classnames';
import { DATE_FORMAT, DATE_FORMAT_HHMM } from '~/const';
import Circle from '~/components/Loader/Circle/Circle';
registerLocale('ru', ru);

interface IDatePicker {
    startDate?: string | null;
    maxDate?: string | null;
    minDate?: string | null;
    handleChange: (value: string) => void;
    loading?: boolean;
    className?: string;
    withTime?: boolean;
    disabled?: boolean;
    debounce?: boolean;
    withoutMinDate?: boolean;
    timeIntervals?: number;
}

const DatePicker = ({
    startDate = null,
    handleChange,
    loading,
    maxDate,
    minDate,
    className,
    withTime,
    disabled,
    debounce,
    withoutMinDate,
    timeIntervals,
}: IDatePicker) => {
    const [date, setDate] = React.useState<string | Date | null>(startDate);
    const debouncedValue = useDebounce<string | Date | null>(date, 750);
    const isFirstRender = useIsFirstRender();

    React.useEffect(() => {
        !isFirstRender &&
            debounce &&
            handleChange(debouncedValue ? moment(debouncedValue).format(DATE_FORMAT_HHMM) : '');
    }, [debouncedValue]);

    React.useEffect(() => {
        !isFirstRender &&
            !debounce &&
            handleChange(date ? moment(date).format(DATE_FORMAT_HHMM) : '');
    }, [date]);

    return (
        <div className={classNames(styles.wrapper, className)}>
            <ReactDatePicker
                locale="ru"
                dateFormat={withTime ? 'dd.MM.yyyy, HH:mm' : 'dd.MM.yyyy'}
                wrapperClassName={styles.datePickerWrapper}
                popperClassName={'popoverDatePicker'}
                onChange={(date) => {
                    setDate(date);
                }}
                selected={startDate ? moment(startDate, DATE_FORMAT_HHMM).toDate() : undefined}
                maxDate={maxDate ? moment(maxDate, DATE_FORMAT).toDate() : undefined}
                minDate={
                    withoutMinDate
                        ? null
                        : minDate
                        ? moment(minDate, DATE_FORMAT).toDate()
                        : moment().toDate()
                }
                showTimeSelect={withTime}
                timeIntervals={timeIntervals ?? 15}
                popperPlacement={'bottom-end'}
                disabled={disabled}
                className={classNames(styles.datePickerInput, {
                    [styles.disabled]: disabled,
                })}
            />
            <div className={styles.icon}>
                {loading ? <Circle className={styles.loader} /> : !disabled && <Calendar />}
            </div>
        </div>
    );
};

export default DatePicker;
