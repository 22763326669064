import * as React from 'react';
import styles from './Groups.module.scss';
import classNames from 'classnames';
import Form from '~/containers/Groups/Form';
import { META, TOAST } from '~/const';
import { deleteMascot } from '~/utils/utils';
import { addToast, setPopup } from '~/store/slices/app/slice';
import store from '~/store';
import { createGroup, getGroup } from '~/store/slices/groups/reducers';
import { setMeta } from '~/store/slices/app/reducers';
import { urlsMap } from '~/utils/urls';
import { useNavigate } from 'react-router-dom';

const CreateGroup = () => {
    const navigate = useNavigate();
    const handleCreate = async (title: string) => {
        store.dispatch(createGroup(title)).then((res) => {
            store.dispatch(setPopup(null));
            store.dispatch(
                addToast({
                    type: TOAST.SUCCESS,
                    title: `Группа ${title} создана`,
                    timer: 3000,
                }),
            );
            const group = res.payload.group;
            store.dispatch(
                setMeta({
                    key: META.CURRENT_GROUP,
                    data: { [META.CURRENT_GROUP]: group?.id || null },
                }),
            );
            if (group?.id) {
                store.dispatch(getGroup(group.id)).finally(() => {
                    const pathnames = location.pathname.split('/');
                    if (pathnames.length > 2) {
                        navigate(urlsMap.index + pathnames[1]);
                    }
                });
            }
        });
    };

    React.useEffect(() => {
        deleteMascot();
    }, []);

    return (
        <div className={styles.content}>
            <div className={classNames('contentHeader', styles.contentHeader)}>
                <h2>Создание группы</h2>
                <p>Создайте свою бесплатную группу{<br />}для персонального пользования.</p>
                <p>
                    В дальнейшем вы сможете улучшить группу{<br />}и пригласить больше сотрудников.
                </p>
            </div>
            <Form handleCreate={handleCreate} />
        </div>
    );
};

export default CreateGroup;
