import { createSlice } from '@reduxjs/toolkit';
import { getMeta, updateMeta } from '~/store/slices/meta/reducers';
import { getAccount } from '~/store/slices/account/reducers';
import { META } from '~/const';

export interface IMetaState {
    data: { [value: string]: any };
}

const initialState: IMetaState = {
    data: {},
};
export const metaSlice = createSlice({
    name: 'meta',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMeta.fulfilled, (state, action) => {
            if (action.payload) {
                const name = action.meta.arg;
                state.data[name] = action.payload[name];
            }
        });
        builder.addCase(updateMeta.fulfilled, (state, action) => {
            if (action.payload) {
                const name = action.meta.arg.name;
                const value = action.payload[name];
                state.data[name] = value;
            }
        });
        builder.addCase(getAccount.fulfilled, (state, action) => {
            if (action.payload) {
                state.data[META.CURRENT_GROUP] = action.payload.meta[META.CURRENT_GROUP]
                    ? action.payload.meta[META.CURRENT_GROUP][META.CURRENT_GROUP]
                    : null;
            }
        });
    },
    selectors: {
        selectMeta: (sliceState, name: string) => sliceState.data[name],
    },
});

export type metaSlice = {
    [metaSlice.name]: ReturnType<typeof metaSlice['reducer']>;
};
export const { selectMeta } = metaSlice.selectors;
