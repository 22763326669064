import { combineReducers } from 'redux';
import { notificationsSlice, INotificationState } from '~/store/slices/notification/slice';
import { appSlice, IAppState } from '~/store/slices/app/slice';
import { calendarSlice, ICalendarState } from '~/store/slices/calendar/slice';
import { accountSlice, IAccountState } from '~/store/slices/account/slice';
import { taskListSlice, ITaskListState } from '~/store/slices/taskList/slice';
import { ITaskState, tasksSlice } from '~/store/slices/task/slice';
import { IProjectState, projectsSlice } from '~/store/slices/project/slice';
import { IUserState, usersSlice } from '~/store/slices/user/slice';
import { IUserListState, userListSlice } from '~/store/slices/userList/slice';
import { ISprintState, sprintsSlice } from '~/store/slices/sprint/slice';
import { IProjectListState, projectListSlice } from '~/store/slices/projectList/slice';
import { IWikiState, wikiSlice } from '~/store/slices/wiki/slice';
import { IMetaState, metaSlice } from '~/store/slices/meta/slice';
import { ITimingState, timingSlice } from '~/store/slices/timings/slice';
import { IGroupState, groupsSlice } from '~/store/slices/groups/slice';

const reducers = {
    [notificationsSlice.name]: notificationsSlice.reducer,
    [calendarSlice.name]: calendarSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [accountSlice.name]: accountSlice.reducer,
    [taskListSlice.name]: taskListSlice.reducer,
    [projectListSlice.name]: projectListSlice.reducer,
    [tasksSlice.name]: tasksSlice.reducer,
    [sprintsSlice.name]: sprintsSlice.reducer,
    [projectsSlice.name]: projectsSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [wikiSlice.name]: wikiSlice.reducer,
    [userListSlice.name]: userListSlice.reducer,
    [metaSlice.name]: metaSlice.reducer,
    [timingSlice.name]: timingSlice.reducer,
    [groupsSlice.name]: groupsSlice.reducer,
};

export interface State {
    [notificationsSlice.name]: INotificationState;
    [calendarSlice.name]: ICalendarState;
    [appSlice.name]: IAppState;
    [accountSlice.name]: IAccountState;
    [taskListSlice.name]: ITaskListState;
    [projectListSlice.name]: IProjectListState;
    [sprintsSlice.name]: ISprintState;
    [tasksSlice.name]: ITaskState;
    [projectsSlice.name]: IProjectState;
    [usersSlice.name]: IUserState;
    [wikiSlice.name]: IWikiState;
    [userListSlice.name]: IUserListState;
    [metaSlice.name]: IMetaState;
    [timingSlice.name]: ITimingState;
    [groupsSlice.name]: IGroupState;
}

export default combineReducers(reducers);
