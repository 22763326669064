import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { USER_META } from '~/const';
import {
    getUserList,
    loadUserListConfig,
    loadUserListOptions,
} from '~/store/slices/userList/reducers';

export const usersAdapter = createEntityAdapter<IUser>();

const defaultOptions = {
    filters: {
        name: '',
    },
};

export interface IUserListState {
    loadedIds: number[];
    totalCount: number;
    isLoading: boolean;
    isOptionsLoading: boolean;
    isMoreLoading: boolean;
    config: ITableConfig[];
    options: IMeta;
    offset: number;
}

const initialState: IUserListState = {
    loadedIds: [],
    totalCount: 0,
    isLoading: false,
    isOptionsLoading: false,
    isMoreLoading: false,
    config: [],
    options: defaultOptions,
    offset: 0,
};

export const userListSlice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
        changeOffset(state, action) {
            state.offset = action.payload;
        },
        changeTotalCount(state, action) {
            state.totalCount = action.payload;
        },
        addUserListIds(state, action) {
            state.loadedIds = [...state.loadedIds, ...action.payload];
            state.totalCount += action.payload.length;
        },
        removeUserListId(state, action) {
            state.loadedIds = state.loadedIds.filter((id) => id !== action.payload);
            state.totalCount -= 1;
        },
        setUserListOptions(state, action) {
            state.options = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserList.pending, (state, action) => {
            if (action.meta.arg.offset === 0) {
                state.isLoading = true;
            } else {
                state.isMoreLoading = true;
            }
        });
        builder.addCase(getUserList.fulfilled, (state, action) => {
            console.log('user loaded');
            if (action.payload) {
                state.totalCount = action.payload.totalCount;
                state.isLoading = false;
                state.isMoreLoading = false;
                if (action.meta.arg.offset === 0) {
                    state.loadedIds = [...action.payload.rows.map((item: IUser) => item.id)];
                } else {
                    state.loadedIds = [
                        ...state.loadedIds,
                        ...action.payload.rows.map((item: IUser) => item.id),
                    ];
                }
                state.offset = action.meta.arg.offset;
            }
        });
        builder.addCase(loadUserListConfig.fulfilled, (state, action) => {
            if (action.payload) {
                state.config = JSON.parse(action.payload[USER_META.TASKS_CONFIG]);
            }
        });
        builder.addCase(loadUserListOptions.fulfilled, (state, action) => {
            if (action.payload) {
                const savedOptions = JSON.parse(action.payload[USER_META.TASKS_OPTIONS]);
                state.options = { ...state.options, ...savedOptions };
                state.isOptionsLoading = false;
            }
        });
        builder.addCase(loadUserListOptions.pending, (state) => {
            state.isOptionsLoading = true;
        });
        // builder.addCase(updateUserListOptions.pending, (state, action) => {
        //     state.options = { ...state.options, ...action.meta.arg };
        // });
    },
    selectors: {
        selectUserListTotalCount: (sliceState) => sliceState.totalCount,
        selectUserListIsLoading: (sliceState) => sliceState.isLoading,
        selectUserListIsMoreLoading: (sliceState) => sliceState.isMoreLoading,
        selectUserListConfig: (sliceState) => sliceState.config,
        selectUserListOffset: (sliceState) => sliceState.offset,
        selectUserListIds: (sliceState) => [...sliceState.loadedIds],
        selectUserListOptions: (sliceState) => sliceState.options ?? defaultOptions,
        selectUserListOptionsIsLoading: (sliceState) => sliceState.isOptionsLoading,
    },
});

export type taskListSlice = {
    [userListSlice.name]: ReturnType<typeof userListSlice['reducer']>;
};
export const {
    changeTotalCount,
    changeOffset,
    addUserListIds,
    removeUserListId,
    setUserListOptions,
} = userListSlice.actions;
export const {
    selectUserListTotalCount,
    selectUserListIsLoading,
    selectUserListIsMoreLoading,
    selectUserListConfig,
    selectUserListOffset,
    selectUserListIds,
    selectUserListOptions,
    selectUserListOptionsIsLoading,
} = userListSlice.selectors;
