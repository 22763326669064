import * as React from 'react';
import styles from '~/containers/Groups/Invites/Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, META, TOAST } from '~/const';
import classNames from 'classnames';
import { addToast, setPopup } from '~/store/slices/app/slice';
import store from '~/store';
import { setMeta } from '~/store/slices/app/reducers';
import { kickFromGroup } from '~/store/slices/groups/reducers';

interface IFC {
    group: IGroup;
}

const LeaveGroup = ({ group }: IFC) => {
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => {
        store.dispatch(setPopup(null));
    };

    const handleLeave = () => {
        setLoading(true);
        store
            .dispatch(kickFromGroup({ groupId: group.id, inviteId: group.invite_id }))
            .then(() => {
                store.dispatch(
                    setMeta({
                        key: META.CURRENT_GROUP,
                        data: { [META.CURRENT_GROUP]: null },
                    }),
                );
                handleClose();
                store.dispatch(
                    addToast({
                        type: TOAST.SUCCESS,
                        title: 'Вы покинули группу',
                        timer: 3000,
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Вы действительно хотите покинуть группу {group.title}?
            </div>
            <div className={classNames(styles.buttons, styles.buttonsMargin)}>
                <Button color={BUTTON_COLOR.GRAY} onClick={handleClose}>
                    Отмена
                </Button>
                <Button color={BUTTON_COLOR.RED} loading={loading} onClick={handleLeave}>
                    Покинуть
                </Button>
            </div>
        </div>
    );
};

export default LeaveGroup;
