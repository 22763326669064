import * as React from 'react';
import styles from './Input.module.scss';
import { Field } from 'formik';
import classNames from 'classnames';
import { INPUT_SIZE, INPUT_TYPE } from '~/const';
import { ReactComponent as EyeOpened } from '~/assets/icons/eye-opened.svg';
import { ReactComponent as EyeClosed } from '~/assets/icons/eye-closed.svg';
import { ReactComponent as Search } from '~/assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import { useDebounce, useIsFirstRender } from 'usehooks-ts';
import Circle from '~/components/Loader/Circle/Circle';

interface IInput {
    label?: string;
    name?: string;
    error?: string;
    isTouched?: boolean;
    validate?: (value: string) => string | undefined;
    type?: string;
    disabled?: boolean;
    size?: string;
    className?: string;
    withoutFormik?: boolean;
    placeholder?: string;
    changeHandler?: (value: string) => void;
    enterHandler?: (value: string) => void;
    defaultValue?: string;
    debounce?: boolean;
    loading?: boolean;
    onlyBorderBottom?: boolean;
    withoutBorder?: boolean;
    containerClassName?: string;
}

// TODO: выпилить Formik
const Input: React.FC<IInput> = ({
    label,
    name,
    error,
    isTouched,
    validate,
    type,
    disabled,
    size,
    className,
    withoutFormik,
    placeholder,
    changeHandler,
    enterHandler,
    defaultValue,
    debounce,
    loading,
    onlyBorderBottom,
    withoutBorder,
    containerClassName,
}) => {
    const { t } = useTranslation();

    const [inputType, setInputType] = React.useState(type ?? INPUT_TYPE.TEXT);
    const defaultTypePassword = type === INPUT_TYPE.PASSWORD;
    const currentTypePassword = inputType === INPUT_TYPE.PASSWORD;
    const typeSearch = type === INPUT_TYPE.SEARCH;
    const inputRef = React.useRef(null);

    const [value, setValue] = React.useState<string>(defaultValue || '');
    const debouncedValue = useDebounce<string>(value, 750);

    const isFirstRender = useIsFirstRender();

    React.useEffect(() => {
        !isFirstRender &&
            value !== defaultValue &&
            changeHandler &&
            changeHandler(debouncedValue.trim());
    }, [debouncedValue]);

    React.useEffect(() => {
        !isFirstRender && !debounce && changeHandler && changeHandler(value.trim());
    }, [value]);

    React.useEffect(() => {
        if (defaultValue?.length && document.activeElement !== inputRef.current) {
            setValue(defaultValue);
            defaultValue = '';
        }
    }, [defaultValue]);

    return (
        <div
            className={classNames(className, {
                [styles.error]: error && isTouched,
            })}
        >
            <div className={styles.inputTop}>
                {label && (
                    <label htmlFor={name} className={styles.inputLabel}>
                        {label}
                    </label>
                )}
                {error && isTouched && <div className={styles.errorMessage}>{t(error)}</div>}
            </div>
            <div
                className={classNames(styles.inputContainer, containerClassName, {
                    [styles.inputTypePassword]: defaultTypePassword,
                    ['disabled']: disabled,
                })}
            >
                {inputType === INPUT_TYPE.TEXTAREA ? (
                    <textarea
                        defaultValue={value}
                        onChange={(event) => setValue(event.target.value)}
                        disabled={disabled}
                        onKeyDown={(event) =>
                            event.key === 'Enter' &&
                            enterHandler &&
                            enterHandler(event.currentTarget.value)
                        }
                        ref={inputRef}
                    />
                ) : withoutFormik ? (
                    <input
                        type={inputType}
                        disabled={disabled}
                        className={classNames({
                            [styles.inputBig]: size === INPUT_SIZE.BIG,
                            [styles.inputSmall]: size === INPUT_SIZE.SMALL,
                            [styles.onlyBorderBottom]: onlyBorderBottom,
                            [styles.withoutBorder]: withoutBorder,
                        })}
                        placeholder={placeholder}
                        onChange={(event) => setValue(event.target.value)}
                        onKeyDown={(event) =>
                            event.key === 'Enter' &&
                            enterHandler &&
                            enterHandler(event.currentTarget.value)
                        }
                        value={value}
                        ref={inputRef}
                    />
                ) : (
                    <Field
                        id={name}
                        name={name}
                        type={inputType}
                        validate={validate}
                        disabled={disabled}
                        className={classNames({
                            [styles.inputBig]: size === INPUT_SIZE.BIG,
                            [styles.inputSmall]: size === INPUT_SIZE.SMALL,
                        })}
                    />
                )}
                {defaultTypePassword && (
                    <div
                        className={styles.inputVisibilityPassword}
                        onClick={() =>
                            setInputType(
                                currentTypePassword ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD,
                            )
                        }
                    >
                        {currentTypePassword ? <EyeOpened /> : <EyeClosed />}
                    </div>
                )}
                {typeSearch && (
                    <div
                        className={styles.search}
                        onClick={() => enterHandler && enterHandler(value)}
                    >
                        <Search />
                    </div>
                )}
                {loading && (
                    <Circle
                        className={classNames(styles.loader, {
                            [styles.withoutPadding]: onlyBorderBottom,
                        })}
                    />
                )}
            </div>
        </div>
    );
};

export default Input;
