import * as React from 'react';
import styles from '~/containers/Groups/Groups.module.scss';
import classNames from 'classnames';
import Input from '~/components/Form/Input/Input';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, INPUT_SIZE, LOADERS, LOADERS_TYPE, META, TOAST } from '~/const';
import { strSanitize } from '~/utils/utils';
import { addToast, selectLoader, setPopup } from '~/store/slices/app/slice';
import store, { useAppSelector } from '~/store';
import { setMeta } from '~/store/slices/app/reducers';
import { deleteGroup } from '~/store/slices/groups/reducers';

interface IFC {
    group: IGroup;
}

const DeleteGroup = ({ group }: IFC) => {
    const [name, setName] = React.useState<string>('');
    const title = strSanitize(group.title)
        .split('')
        .filter((_, index) => index <= 15)
        .join('');
    const isGroupDeletePending = useAppSelector((state) =>
        selectLoader(state, LOADERS.GROUP, LOADERS_TYPE.LOADING),
    );

    const closeHandler = () => {
        store.dispatch(setPopup(null));
    };

    const deleteHandler = async () => {
        store.dispatch(deleteGroup(group.id)).then(() => {
            store.dispatch(
                setMeta({ key: META.CURRENT_GROUP, data: { [META.CURRENT_GROUP]: null } }),
            );
            closeHandler();
            store.dispatch(
                addToast({
                    type: TOAST.SUCCESS,
                    title: `Группа ${group.title} удалена`,
                    timer: 3000,
                }),
            );
        });
    };

    return (
        <div className={styles.content}>
            <div className={classNames('contentHeader', styles.contentHeader)}>
                <h2>Удаление группы {group.title}</h2>
                <p>
                    Внимание! Вместе с удалением группы
                    <br />
                    пропадут все проекты и задачи.
                </p>
                <p>
                    Введите «{title}», чтобы подтвердить
                    <br />
                    удаление группы
                </p>
            </div>
            <div className={styles.form}>
                <Input
                    withoutFormik={true}
                    label="Название группы"
                    className={classNames(styles.bigLabel)}
                    size={INPUT_SIZE.BIG}
                    defaultValue={name}
                    changeHandler={(value) => setName(value)}
                    enterHandler={() => {
                        if (name === title) {
                            deleteHandler();
                        }
                    }}
                />
                <div className={styles.buttons}>
                    <Button color={BUTTON_COLOR.GRAY} onClick={closeHandler}>
                        Отмена
                    </Button>
                    <Button
                        color={BUTTON_COLOR.RED}
                        disabled={name !== title}
                        onClick={deleteHandler}
                        loading={isGroupDeletePending}
                    >
                        Удалить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeleteGroup;
