import * as React from 'react';
import CreatableSelect from 'react-select/creatable';
import { components, GroupBase, OptionsOrGroups } from 'react-select';
import { customStyles } from '~/components/Form/Select/style';
import classNames from 'classnames';
import styles from '~/components/Form/Select/Select.module.scss';
import { getColor } from '~/utils/utils';
import { ReactComponent as Chevron } from '~/assets/icons/chevron.svg';
import Circle from '~/components/Loader/Circle/Circle';
import {
    MultiValueProps,
    MultiValueRemoveProps,
} from 'react-select/dist/declarations/src/components/MultiValue';
import { DropdownIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';

interface IFC {
    options: OptionsOrGroups<ISelectOption, GroupBase<ISelectOption>>;
    label?: string;
    className?: string;
    classNameWrapper?: string;
    defaultValue?: ISelectOption[];
    multiColor?: boolean;
    handleCreate?: (value: string) => void;
    handleChange?: (value: ISelectValue) => void;
    loading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    formatCreateLabel?: string;
    noOptionsMessage?: string;
    onInputChange?: (value: string) => void;
}

const CreatableSelectField = ({
    options,
    label,
    className,
    classNameWrapper,
    defaultValue,
    multiColor,
    handleCreate,
    handleChange,
    loading,
    disabled,
    placeholder,
    formatCreateLabel,
    noOptionsMessage,
    onInputChange,
}: IFC) => {
    return (
        <div
            className={classNames(styles.selectContainer, styles.creatableSelect, classNameWrapper)}
        >
            <div className={styles.selectTop}>
                {label && <label className={styles.selectLabel}>{label}</label>}
            </div>
            <CreatableSelect
                // @ts-ignore
                isMulti
                // @ts-ignore
                options={options}
                // @ts-ignore
                value={defaultValue}
                onCreateOption={handleCreate}
                onChange={handleChange}
                onInputChange={onInputChange}
                className={classNames(className, {
                    ['disabled']: disabled || loading,
                })}
                isLoading={loading}
                placeholder={placeholder || ''}
                formatCreateLabel={(inputValue: string) =>
                    `${formatCreateLabel ?? 'Создать'} «${inputValue}»`
                }
                isClearable={false}
                backspaceRemovesValue={false}
                // @ts-ignore
                styles={customStyles(undefined, multiColor)}
                components={{
                    DropdownIndicator: (props: DropdownIndicatorProps) => {
                        if (disabled) {
                            return null;
                        } else {
                            return (
                                <components.DropdownIndicator {...props}>
                                    <Chevron />
                                </components.DropdownIndicator>
                            );
                        }
                    },
                    LoadingIndicator: () => {
                        return <Circle className={classNames(styles.creatableLoading)} />;
                    },
                    MultiValue: ({ children, ...props }: MultiValueProps<ISelectOption>) => (
                        <components.MultiValue
                            {...props}
                            className={classNames(
                                multiColor && getColor(String(children)),
                                styles.multiValue,
                            )}
                        >
                            {children}
                        </components.MultiValue>
                    ),
                    NoOptionsMessage: () => (
                        <div className={styles.noOptionsMessage}>
                            {noOptionsMessage ?? 'Ничего не найдено'}
                        </div>
                    ),
                    MultiValueRemove: (props: MultiValueRemoveProps) => {
                        if (disabled) {
                            return null;
                        } else {
                            return <components.MultiValueRemove {...props} />;
                        }
                    },
                }}
            />
        </div>
    );
};

export default CreatableSelectField;
