import * as React from 'react';
import styles from './Calendar.module.scss';
import classNames from 'classnames';
import Popover from '~/containers/Tasks/Calendar/Popover';
import { useOnClickOutside } from 'usehooks-ts';
import moment, { Moment } from 'moment';
import { prepareTimeFromMinutes, generateRangeArray, prepareHMFromSeconds } from '~/utils/utils';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '~/const';
import store, { useAppSelector } from '~/store';
import { selectCalendar, selectCalendarUserNorm } from '~/store/slices/calendar/slice';
import { selectTasks } from '~/store/slices/task/slice';
import { getCalendarDay } from '~/store/slices/calendar/reducers';

interface IFC {
    className: string;
}

export interface IPopoverPopup {
    date: Moment;
    isCurrentDate: boolean;
    top: number;
    right: number;
}

export const isFutureDay = (day?: Moment) => day && day.diff(moment()) > 0;

export const isSameDay = (date?: string, day?: Moment) =>
    day && moment(date, DATE_FORMAT).isSame(day, 'dates');

export const isFutureWeek = (week: number, date?: string) =>
    date && week === moment(date, 'DD.MM.YYYY').week();

export const timeDiff = (stime: string | Moment, ftime: string | Moment) => {
    return moment(ftime).diff(moment(stime));
};

export const calculateTimeTask = (
    time: number,
    timing: ITiming[] = [],
    isSame: boolean,
    day?: Moment,
) => {
    let sum = time;
    sum += timing.reduce((acc, item) => {
        const diff = timeDiff(item.stime, item.ftime || moment());
        if (day && moment(day).isSame(item.stime, 'days')) {
            if (isSame) {
                if (!moment(item.stime).isSameOrAfter(moment(), 'dates')) {
                    acc += diff;
                }
            } else {
                acc += diff;
            }
        } else {
            acc -= diff;
        }
        return acc;
    }, 0);

    return sum < 0 ? 15 * 1000 * 60 : sum > 0 ? sum : time;
};

const calculateTimeWeekTasks = (
    isLeft: boolean,
    time: number,
    timing: ITiming[] = [],
    userId?: number,
    week?: number,
) => {
    let sum: number | undefined = time;
    sum += timing?.reduce((acc, item) => {
        const diff = timeDiff(item.stime, item.ftime || moment());
        if (week && week === moment(item.stime).week()) {
            if (!item.userid || item.userid === userId) {
                acc += diff;
            }
        } else {
            acc -= diff;
        }
        return acc;
    }, 0);

    if (isLeft) {
        return sum < 0 ? 15 * 1000 * 60 : sum > 0 ? sum : time;
    } else {
        return sum > 0 ? sum : time;
    }
};

const checkTaskExecutive = (executive?: number, accountId?: number) => executive !== accountId;

const isCurrentDay = (day: Moment) => day && day.isSame(moment(), 'dates');
const Calendar = ({ className }: IFC) => {
    const calendar = useAppSelector((state) => selectCalendar(state));
    const userNorm = useAppSelector((state) => selectCalendarUserNorm(state));
    const ref = React.useRef(null);
    const [popoverVisible, setPopoverVisible] = React.useState<IPopoverPopup | undefined>();
    const startDate = moment().weekday(-14);
    const rangeDayArray: Moment[] = generateRangeArray(35, startDate, 'days');
    const rangeWeekArray: number[] = generateRangeArray(35, startDate, 'days', true);

    const openPopover = (props: IPopoverPopup) => {
        setPopoverVisible(props);
    };

    const closePopover = () => {
        setPopoverVisible(undefined);
    };

    useOnClickOutside(ref, closePopover);

    const getDayData = (day: Moment): ICalendarDay | undefined => {
        return calendar.find((item) => item.date === day.format(SERVER_DATE_FORMAT));
    };

    // const getDayData = (day: Moment) => {
    //     const tasksGetDayData: ITask[] = [];
    //     const taskIds: number[] = [];
    //
    //
    //     state.calendar.ids.forEach((id) => {
    //         const task = { ...state.tasks?.[id] };
    //
    //         if (!task) return;
    //
    //         if (
    //             checkTaskExecutive(task.executive, state.accountId) &&
    //             task.type !== TASK_TYPES.MEETS
    //         ) {
    //             task.wdate = '';
    //         }
    //
    //         const currentTask = { ...task };
    //         const addedTimings: ITiming[] = [];
    //         if (isSameDay(task.wdate, day)) {
    //             if (!taskIds.includes(task.id)) {
    //                 taskIds.push(task.id);
    //                 tasksGetDayData.push(currentTask);
    //             }
    //         } else {
    //             task.timings = task?.timings?.filter(
    //                 (timing) => !timing.userid || timing.userid === state.accountId,
    //             );
    //             if (task.timings) {
    //                 task.timings.forEach((timing) => {
    //                     if (moment(day).isSame(timing.stime, 'days')) {
    //                         addedTimings.push(timing);
    //
    //                         if (!taskIds.includes(task.id)) {
    //                             taskIds.push(task.id);
    //                             currentTask.timings = addedTimings;
    //                             tasksGetDayData.push(currentTask);
    //                         }
    //                     }
    //                 });
    //             }
    //         }
    //     });
    //     let allTime = 0;
    //
    //     tasksGetDayData.forEach((task) => {
    //         const project = task?.project ? state.projects?.[task.project] : null;
    //
    //         const status = project?.statuses.find((item) => item.id === task.status);
    //
    //         let taskTime = 0;
    //
    //         if (isFutureDay(task.wdate, day) && status?.is_open) {
    //             if (!!task.has_subtasks) {
    //                 taskTime = difficultyToMs(15);
    //             } else {
    //                 taskTime = difficultyToMs(task.difficulty);
    //             }
    //
    //             if (task.timings && task.difficulty) {
    //                 taskTime = calculateTimeTask(taskTime, task.timings, false, undefined);
    //             }
    //         } else if (task.timings) {
    //             taskTime = calculateTimeTask(taskTime, task.timings, true, day);
    //         }
    //
    //         allTime += taskTime;
    //     });
    //
    //     return { tasks: tasksGetDayData, time: allTime / 1000 };
    // };

    const weekTotalTime = (week: number) => {
        let totalTime = 0;
        let remainingTime = 0;

        rangeDayArray.map((day) => {
            if (day.week() == week) {
                const dayData = getDayData(day);
                totalTime += (dayData?.totalTime ?? 0) / 60;
                remainingTime += (dayData?.plannedTime ?? 0) / 60;
            }
        });

        return {
            totalTime,
            remainingTime,
        };
    };

    return (
        <div className={classNames(styles.calendarWrapper, className)}>
            <div className={styles.daysWeek}>
                {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ''].map((day, key) => (
                    <div key={key} className={styles.dayWeek}>
                        {day}
                    </div>
                ))}
            </div>
            <div className={styles.calendar}>
                <>
                    {rangeDayArray.map((day, key) => {
                        const dayData = getDayData(day);
                        const time = (dayData?.totalTime ?? 0) / 60 / 60;
                        const planned = (dayData?.plannedTime ?? 0) / 60 / 60;
                        return (
                            <div
                                className={classNames(styles.day, {
                                    [styles.active]:
                                        popoverVisible && day.isSame(popoverVisible.date, 'dates'),
                                    [styles.dayToday]: day.isSame(new Date(), 'day'),
                                    [styles.dayBefore]: day.isBefore(new Date(), 'day'),
                                    [styles.cursorPointer]: !!dayData?.tasksCount,
                                })}
                                key={key}
                                onClick={() => {
                                    if (!dayData?.fullData) {
                                        store.dispatch(
                                            getCalendarDay(day.format(SERVER_DATE_FORMAT)),
                                        );
                                    }
                                    openPopover({
                                        date: day,
                                        isCurrentDate: day.isSame(moment(), 'dates'),
                                        top: 35 + 55 * Math.ceil((key + 1) / 7),
                                        right: 55 * (Math.ceil((key + 1) / 7) * 7 - key),
                                    });
                                }}
                            >
                                {!!dayData?.tasksCount && (
                                    <div className={styles.tasksInDay}>{dayData.tasksCount}</div>
                                )}
                                <span
                                    className={classNames(styles.dayTime, {
                                        [styles.currentDate]: isCurrentDay(day),
                                        ['colorSuccess']: isFutureDay(day)
                                            ? planned <= 1.1 * userNorm && planned >= userNorm * 0.8
                                            : time >= userNorm,
                                        ['colorWarning']: isFutureDay(day)
                                            ? planned < 0.8 * userNorm
                                            : time < userNorm && time >= userNorm * 0.5,
                                        ['colorError']: isFutureDay(day)
                                            ? planned > 1.1 * userNorm
                                            : time < userNorm && time < userNorm * 0.5,
                                    })}
                                >
                                    {!isCurrentDay(day) &&
                                        ((isFutureDay(day)
                                            ? dayData?.plannedTime
                                            : dayData?.totalTime) || 0) > 0 &&
                                        prepareHMFromSeconds(
                                            (isFutureDay(day)
                                                ? dayData?.plannedTime
                                                : dayData?.totalTime) || 0,
                                            true,
                                        )}
                                    {isCurrentDay(day) && (
                                        <>
                                            <p>
                                                {prepareHMFromSeconds(
                                                    dayData?.totalTime || 0,
                                                    true,
                                                )}
                                            </p>
                                            {prepareTimeFromMinutes(
                                                (dayData?.plannedTime ?? 0) / 60,
                                                true,
                                            )}
                                        </>
                                    )}
                                </span>
                                <div className={styles.dayDate}>{day.format('D')}</div>
                            </div>
                        );
                    })}
                    <div className={styles.weekTotalList}>
                        {rangeWeekArray.map((week, key) => {
                            const time = weekTotalTime(week);
                            return (
                                <div key={key} className={styles.weekTotal}>
                                    <span
                                        className={classNames(styles.dayTime, {
                                            [styles.currentDate]: week === moment().week(),
                                        })}
                                    >
                                        {!!time.totalTime && (
                                            <p>{prepareTimeFromMinutes(time.totalTime, true)}</p>
                                        )}
                                        {!!time.remainingTime &&
                                            prepareTimeFromMinutes(time.remainingTime, true)}
                                    </span>
                                    <div className={styles.weekNumber}>{week}</div>
                                </div>
                            );
                        })}
                    </div>
                </>
            </div>
            <div ref={ref}>
                <Popover props={popoverVisible} closeHandler={closePopover} />
            </div>
        </div>
    );
};

export default Calendar;
