import * as React from 'react';
import styles from '~/containers/Groups/Invites/Invites.module.scss';
import Button from '~/components/Button/Button';
import { BUTTON_COLOR, INVITATIONS, LOADERS, LOADERS_TYPE, META } from '~/const';
import classNames from 'classnames';
import { apiGetGroup } from '~/api/user';
import store, { useAppSelector } from '~/store';
import { selectLoader, setPopup, updateLoader } from '~/store/slices/app/slice';
import { setMeta } from '~/store/slices/app/reducers';
import { acceptGroupInvite, refuseGroupInvite } from '~/store/slices/groups/reducers';
import { update as updateGroup } from '~/store/slices/groups/slice';

interface IFC {
    group: IGroup;
}

const Accept = ({ group }: IFC) => {
    const isGroupLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.GROUP, LOADERS_TYPE.LOADING),
    );

    const setLoader = () => {
        store.dispatch(
            updateLoader({
                loaderGroup: LOADERS.GROUP,
                loaderName: LOADERS_TYPE.LOADING,
                state: true,
            }),
        );
    };

    const removeLoader = () => {
        store.dispatch(
            store.dispatch(
                updateLoader({
                    loaderGroup: LOADERS.GROUP,
                    loaderName: LOADERS_TYPE.LOADING,
                    state: false,
                }),
            ),
        );
    };

    const handleClose = () => {
        store.dispatch(setPopup(null));
    };

    const handleAccept = () => {
        setLoader();
        store
            .dispatch(acceptGroupInvite(group.id))
            .then(() => {
                store.dispatch(
                    setMeta({
                        key: META.CURRENT_GROUP,
                        data: { [META.CURRENT_GROUP]: group.id },
                    }),
                );
                //TODO: Я думаю это стоит отсюда убрать, сама группа уже должна быть у пользователя вместе с приглашением.
                apiGetGroup(group.id).then((res) => {
                    store.dispatch(
                        updateGroup({ ...res.group, is_invite: INVITATIONS.NOT_INVITED }),
                    );
                });
                handleClose();
            })
            .finally(() => {
                removeLoader();
            });
    };

    const handleRefuse = () => {
        setLoader();
        store
            .dispatch(refuseGroupInvite(group.id))
            .then(() => {
                handleClose();
            })
            .finally(() => {
                removeLoader();
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Вас пригласили в группу {group.title}</div>
            <div className={classNames(styles.buttons, styles.buttonsMargin)}>
                <Button color={BUTTON_COLOR.GRAY} loading={isGroupLoading} onClick={handleRefuse}>
                    Отклонить
                </Button>
                <Button color={BUTTON_COLOR.BLUE} loading={isGroupLoading} onClick={handleAccept}>
                    Принять
                </Button>
            </div>
        </div>
    );
};

export default Accept;
