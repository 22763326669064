import * as React from 'react';
import styles from './Sprints.module.scss';
import classNames from 'classnames';
import { urlsMap } from '~/utils/urls';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as SprintsIcon } from '~/assets/icons/sprints.svg';
import Button from '~/components/Button/Button';
import {
    BUTTON_COLOR,
    BUTTON_SIZE,
    DATE_FORMAT,
    DATE_FORMAT_HHMM,
    INPUT_SIZE,
    LOADERS,
    LOADERS_TYPE,
    SERVER_DATE_FORMAT,
    SPRINT_FIELDS,
    TASK_FIELDS,
} from '~/const';
import Input from '~/components/Form/Input/Input';
import Param from '~/components/Param/Param';
import SelectField from '~/components/Form/Select/Select';
import DatePicker from '~/components/Form/DatePicker/DatePicker';
import moment from 'moment';
import { useDocumentTitle } from 'usehooks-ts';
import Backlog from '~/containers/Projects/Sprints/Backlog';
import SprintTasks from '~/containers/Projects/Sprints/Tasks';
import Times from '~/containers/Projects/Sprints/Times';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ReactComponent as Pencil } from '~/assets/icons/pencil.svg';
import store, { useAppSelector } from '~/store';
import { selectLoader, selectSubLoaders } from '~/store/slices/app/slice';
import {
    createSprint,
    getSprint,
    loadBacklogIds,
    updateSprint,
} from '~/store/slices/sprint/reducers';
import {
    selectBacklogIds,
    selectSprint,
    selectSprintTasksIds,
    updateLists,
} from '~/store/slices/sprint/slice';
import { updateTask } from '~/store/slices/task/reducers';
import { selectProject } from '~/store/slices/project/slice';
import { getProject } from '~/store/slices/project/reducers';
import { moveArrayElement } from '~/utils/utils';

export const sprintStatuses = {
    '0': 'Планирование',
    '1': 'В работе',
    '2': 'Закрыт',
};

export const DROPPABLE_ID = {
    TASKS_BACKLOG: 'tasksBacklog',
    TASKS_SPRINT: 'tasksSprint',
};

const Sprints = () => {
    const sprintLoaders = useAppSelector((state) => selectSubLoaders(state, LOADERS.SPRINT));
    const sprintId = Number(useParams().sprintId);
    const projectId = Number(useParams().projectId);
    const navigate = useNavigate();
    const project = useAppSelector((state) => selectProject(state, projectId));
    const sprint = useAppSelector((state) => selectSprint(state, sprintId));
    const prepareSprints: ISelectOption = {};
    const isLoading = useAppSelector((state) =>
        selectLoader(state, LOADERS.SPRINT, LOADERS_TYPE.LOADING),
    );
    const backlogTasksIds = useAppSelector((state) => selectBacklogIds(state));
    const sprintTasksIds = useAppSelector((state) => selectSprintTasksIds(state));

    const handleCreateSprint = () => {
        store
            .dispatch(
                createSprint({
                    project_id: projectId,
                    title: `#${moment().format('W')}${moment().format('YYYY')}:`,
                }),
            )
            .then((res) => {
                navigate(
                    `${urlsMap.projectList}/${projectId}${urlsMap.sprintList}/${res.payload.sprint.id}`,
                );
            });
    };

    React.useEffect(() => {
        if ((!sprint || !sprint?.fullData) && sprintId > 0) {
            store.dispatch(getSprint(sprintId));
        }
        if (sprintId === 0) {
            handleCreateSprint();
        }
    }, [sprintId, sprint]);

    React.useEffect(() => {
        if (!project || !project?.fullData) {
            store.dispatch(getProject(projectId));
        }
    }, [project, projectId]);

    React.useEffect(() => {
        store.dispatch(loadBacklogIds({ offset: 0, projectId, sprintId: `not:${sprintId}` }));
    }, [projectId, sprintId]);

    useDocumentTitle(`Спринты ${project?.title ? 'проекта ' + project.title : ''}`);

    if (!project || !project?.fullData || !sprint) {
        return null;
    }

    const openStatuses = project?.statuses
        .filter((status) => status.is_open)
        .map((status) => status.id);

    project?.sprints?.map((oneSprint) => {
        prepareSprints[oneSprint.id] = oneSprint.id === sprint.id ? sprint.title : oneSprint.title;
    });

    const handleUpdateSprint = (data: IFieldValue) => {
        store.dispatch(updateSprint({ id: sprintId, data }));
    };

    const handlerDragEnd = async (result: DropResult) => {
        const backlogIds: number[] = [...backlogTasksIds] as number[];
        const sprintIds: number[] = [...sprintTasksIds] as number[];

        if (result.source?.droppableId === result.destination?.droppableId) {
            switch (result.destination?.droppableId) {
                case DROPPABLE_ID.TASKS_BACKLOG:
                    store.dispatch(
                        updateLists({
                            backlogIds: moveArrayElement(
                                [...backlogTasksIds],
                                result.source.index,
                                result.destination.index,
                            ),
                            sprintIds: sprintTasksIds,
                        }),
                    );
                    break;
                case DROPPABLE_ID.TASKS_SPRINT:
                    store.dispatch(
                        updateLists({
                            backlogIds: backlogIds,
                            sprintIds: moveArrayElement(
                                [...sprintTasksIds],
                                result.source.index,
                                result.destination.index,
                            ),
                        }),
                    );
                    break;
            }
            return;
        }

        const taskId = Number(result.draggableId);
        const destinationIndex = result.destination?.index || 0;
        const sourceIndex = result.source.index;

        switch (result.destination?.droppableId) {
            case DROPPABLE_ID.TASKS_BACKLOG:
                if (taskId) {
                    backlogIds.splice(destinationIndex, 0, taskId);
                    sprintIds.splice(sourceIndex, 1);
                    store.dispatch(updateTask({ data: { [TASK_FIELDS.SPRINT]: 0 }, task: taskId }));
                    store.dispatch(
                        updateLists({
                            backlogIds: backlogIds,
                            sprintIds: sprintIds,
                        }),
                    );
                }
                break;
            case DROPPABLE_ID.TASKS_SPRINT:
                if (taskId) {
                    backlogIds.splice(sourceIndex, 1);
                    sprintIds.splice(destinationIndex, 0, taskId);
                    store.dispatch(
                        updateTask({ data: { [TASK_FIELDS.SPRINT]: sprintId }, task: taskId }),
                    );
                    store.dispatch(
                        updateLists({
                            backlogIds: backlogIds,
                            sprintIds: sprintIds,
                        }),
                    );
                }
                break;
            default:
                break;
        }
    };

    if (!prepareSprints[sprintId]) {
        prepareSprints[sprintId] = sprint?.title;
    }

    return (
        <div className="wrapper">
            <div className={classNames('content', styles.content)}>
                <div className="contentHeader">
                    <h2>Проекты</h2>
                    <div className="contentTitle">
                        <Link to={`${urlsMap.projectList}/${project.id}`}>{project.title} / </Link>
                        Спринты
                    </div>
                </div>
                <div className={classNames('contentSubtitle', styles.contentSubtitle)}>
                    <div className={styles.subtitle}>
                        <SprintsIcon />
                        <span>Спринты проекта {project.title}</span>
                    </div>
                    <Link
                        className={styles.pencil}
                        to={`${urlsMap.projectList}/${project.id}${urlsMap.processList}/${sprintId}`}
                    >
                        <Pencil />
                    </Link>
                    <SelectField
                        options={prepareSprints}
                        onChange={(newValue: ISelectValue) =>
                            navigate(
                                `${urlsMap.projectList}/${projectId}${urlsMap.sprintList}/${
                                    newValue!.value
                                }`,
                            )
                        }
                        className={styles.selectSprints}
                        withoutBorder={true}
                        defaultValue={sprintId}
                    />
                </div>
                <div className={styles.info}>
                    <Input
                        withoutFormik={true}
                        defaultValue={sprint?.title}
                        onlyBorderBottom={true}
                        size={INPUT_SIZE.SMALL}
                        className={styles.title}
                        debounce={true}
                        changeHandler={(value: string) =>
                            handleUpdateSprint({ [SPRINT_FIELDS.TITLE]: value })
                        }
                        loading={sprintLoaders[SPRINT_FIELDS.TITLE]}
                    />
                    <div className={styles.dates}>
                        <Param label="Период с" withoutBorder={true} className={styles.date}>
                            <DatePicker
                                startDate={
                                    sprint?.date_start
                                        ? moment(sprint.date_start, SERVER_DATE_FORMAT).format(
                                              DATE_FORMAT,
                                          )
                                        : null
                                }
                                handleChange={(value: string) =>
                                    handleUpdateSprint({
                                        [SPRINT_FIELDS.DATE_START]: moment(value, DATE_FORMAT_HHMM)
                                            .format(SERVER_DATE_FORMAT)
                                            .toString(),
                                    })
                                }
                                className={styles.datePicker}
                                loading={sprintLoaders[SPRINT_FIELDS.DATE_START]}
                                maxDate={
                                    sprint?.date_finish
                                        ? moment(sprint.date_finish, SERVER_DATE_FORMAT).format(
                                              DATE_FORMAT,
                                          )
                                        : null
                                }
                            />
                        </Param>
                        <Param label="до" withoutBorder={true} className={styles.date}>
                            <DatePicker
                                startDate={
                                    sprint?.date_finish
                                        ? moment(sprint.date_finish, SERVER_DATE_FORMAT).format(
                                              DATE_FORMAT,
                                          )
                                        : null
                                }
                                handleChange={(value: string) =>
                                    handleUpdateSprint({
                                        [SPRINT_FIELDS.DATE_FINISH]: moment(value, DATE_FORMAT_HHMM)
                                            .format(SERVER_DATE_FORMAT)
                                            .toString(),
                                    })
                                }
                                className={styles.datePicker}
                                loading={sprintLoaders[SPRINT_FIELDS.DATE_FINISH]}
                            />
                        </Param>
                    </div>
                    <Param label="Статус" withoutBorder={true} className={styles.statuses}>
                        <SelectField
                            options={sprintStatuses}
                            onChange={(newValue: ISelectValue) =>
                                handleUpdateSprint({
                                    [SPRINT_FIELDS.STATUS]: Number(newValue!.value),
                                })
                            }
                            withoutBorder={true}
                            className={styles.statusesSelect}
                            defaultValue={sprint?.status}
                            loading={sprintLoaders[SPRINT_FIELDS.STATUS]}
                            withoutSort={true}
                        />
                    </Param>
                    <Button
                        size={BUTTON_SIZE.SMALL}
                        color={BUTTON_COLOR.BLUE}
                        onClick={handleCreateSprint}
                        loading={isLoading}
                    >
                        Новый спринт
                    </Button>
                </div>
                <div className={styles.container}>
                    {sprintId ? (
                        <>
                            <DragDropContext onDragEnd={handlerDragEnd}>
                                <Backlog sprintId={sprintId} projectId={projectId} />
                                <SprintTasks sprintId={sprintId} />
                            </DragDropContext>
                            <Times
                                sprintId={sprintId}
                                openStatuses={openStatuses}
                                tasksIds={sprintTasksIds}
                            />
                        </>
                    ) : (
                        <div className="empty">Спринт не найден</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Sprints;
