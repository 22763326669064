import * as React from 'react';
import styles from './Aside.module.scss';
import { urlsMap } from '~/utils/urls';
import { Link, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as Cross } from '~/assets/icons/cross.svg';
import { GROUP_ROLE, META } from '~/const';
import { useAppSelector } from '~/store';
import { selectAppMeta, selectProjectsDictionary } from '~/store/slices/app/slice';
import { selectAccount } from '~/store/slices/account/slice';
import { selectTask } from '~/store/slices/task/slice';

const TaskListAside = ({
    projectId,
    visibleCreate,
}: {
    projectId?: number;
    visibleCreate: boolean;
}) => {
    return (
        <>
            {visibleCreate && (
                <Link
                    to={urlsMap.createTask}
                    state={{
                        projectId,
                    }}
                >
                    <Cross />
                </Link>
            )}
        </>
    );
};

const ProjectListAside = ({ visibleCreate }: { visibleCreate: boolean }) => {
    return (
        <>
            {visibleCreate && (
                <Link to={urlsMap.createProject}>
                    <Cross />
                </Link>
            )}
        </>
    );
};

const Aside = () => {
    const currentGroup = useAppSelector((state) => selectAppMeta(state, META.CURRENT_GROUP));
    const account = useAppSelector((state) => selectAccount(state));
    const projects = useAppSelector((state) => selectProjectsDictionary(state));
    const location = useLocation();
    const isTask = location.pathname.includes('tasks');
    const urlParam = Object.values(useParams())[0] ?? 0;
    const task = useAppSelector((state) => selectTask(state, Number(urlParam)));

    let projectId: number;

    if (isTask && task) {
        projectId = task?.pid ?? 0;
    } else {
        projectId = Number(Object.values(useParams())[0]);
    }

    const activeGroup = account?.groups?.find((group) => group.id === currentGroup);
    const canProjectCreate =
        [GROUP_ROLE.OWNER, GROUP_ROLE.MANAGER].includes(activeGroup?.group_role_id || 1) &&
        !!account.groups?.length;

    return (
        <aside className={styles.aside}>
            <Routes>
                <Route
                    path={`${urlsMap.taskList}/*`}
                    element={
                        <TaskListAside
                            projectId={projectId}
                            visibleCreate={
                                (projects && Object.values(projects).length > 0) ?? false
                            }
                        />
                    }
                />
                <Route path={`${urlsMap.projectList}/*`} element={<Outlet />}>
                    <Route index element={<ProjectListAside visibleCreate={canProjectCreate} />} />
                    <Route
                        path=":id"
                        element={
                            <TaskListAside
                                projectId={projectId}
                                visibleCreate={
                                    (projects && Object.values(projects).length > 0) ?? false
                                }
                            />
                        }
                    />
                    <Route
                        path="*"
                        element={<ProjectListAside visibleCreate={canProjectCreate} />}
                    />
                </Route>
            </Routes>
        </aside>
    );
};

export default Aside;
