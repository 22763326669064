import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetMeta, apiUpdateMeta } from '~/api/user';

const getMeta = createAsyncThunk('meta/load', async (name: string) => {
    return await apiGetMeta(name);
});

const updateMeta = createAsyncThunk('meta/update', async (data: { name: string; data: any }) => {
    return await apiUpdateMeta(data.name, data.data);
});

export { getMeta, updateMeta };
