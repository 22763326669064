import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    apiCreateProject,
    apiCreateStatus,
    apiDeleteProject,
    apiDeleteStatus,
    apiGetProject,
    apiGetProjectTags,
    apiUpdateProject,
    apiUpdateStatus,
} from '~/api/project';
import { put } from '~/utils/api';

const getProject = createAsyncThunk('projects/add', async (id: number) => {
    return await apiGetProject(id);
});

const createProject = createAsyncThunk(
    'projects/create',
    async (data: { groupId: string; title: string }) => {
        return await apiCreateProject({
            group_id: data.groupId,
            title: data.title,
        });
    },
);

const removeProject = createAsyncThunk('projects/delete', async (id: number) => {
    return await apiDeleteProject(id);
});

const updateStatusOrder = createAsyncThunk(
    'projects/statusOrder',
    async (data: { projectId: number; data: number[] }) => {
        return await put(`projects/${data.projectId}/status/order`, data.data);
    },
);

const updateProject = createAsyncThunk(
    'projects/update',
    async (data: { id: number; data: any }) => {
        return await apiUpdateProject(data.id, data.data);
    },
);
const toggleArchiveProject = createAsyncThunk(
    'projects/toggleArchive',
    async (data: { id: number; activate: boolean }) => {
        return await put(`projects/${data.id}/${data.activate ? 'unarchive' : 'archive'}`);
    },
);

const getProjectTags = createAsyncThunk('projects/loadTags', async (id: number) => {
    return await apiGetProjectTags(id);
});

const updateProjectStatus = createAsyncThunk(
    'projects/updateStatus',
    async (data: { projectId: number; status: IStatus }) => {
        return await apiUpdateStatus(data.projectId, data.status.id, data.status);
    },
);

const createProjectStatus = createAsyncThunk(
    'projects/createStatus',
    async (data: {
        projectId: number;
        status: { title: string; is_open: boolean; order: number };
    }) => {
        return await apiCreateStatus(data.projectId, data.status);
    },
);

const deleteProjectStatus = createAsyncThunk(
    'projects/deleteStatus',
    async (data: { projectId: number; statusId: number }) => {
        return await apiDeleteStatus(data.projectId, data.statusId);
    },
);

export {
    getProject,
    toggleArchiveProject,
    updateProject,
    createProject,
    removeProject,
    getProjectTags,
    updateStatusOrder,
    updateProjectStatus,
    createProjectStatus,
    deleteProjectStatus,
};
