import reducers from '~/store/reducers';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

const PRODUCTION_MODE = 'production';

const store = configureStore({
    reducer: reducers,
});

if (process.env.NODE_ENV !== PRODUCTION_MODE) {
    window.store = store;
}

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
