import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    apiAcceptInvite,
    apiCreateGroup,
    apiDeleteGroup,
    apiGetGroup,
    apiKickInvite,
    apiRefuseInvite,
} from '~/api/user';

const createGroup = createAsyncThunk('group/create', async (title: string) => {
    return await apiCreateGroup({ title });
});

const deleteGroup = createAsyncThunk('group/delete', async (groupId: number) => {
    return await apiDeleteGroup(groupId);
});

const refuseGroupInvite = createAsyncThunk('group/refuse', async (groupId: number) => {
    return await apiRefuseInvite(groupId);
});

const acceptGroupInvite = createAsyncThunk('group/accept', async (groupId: number) => {
    return await apiAcceptInvite(groupId);
});

const kickFromGroup = createAsyncThunk(
    'group/kick',
    async (data: { groupId: number; inviteId: number }) => {
        return await apiKickInvite(data.groupId, data.inviteId);
    },
);

const getGroup = createAsyncThunk('group/load', async (groupId: number) => {
    return await apiGetGroup(groupId);
});

export { createGroup, deleteGroup, refuseGroupInvite, acceptGroupInvite, kickFromGroup, getGroup };
