import { ControlProps, CSSObjectWithLabel, GroupBase, OptionProps } from 'react-select';

type IStyle = CSSObjectWithLabel;
type IControlState = ControlProps<
    { value: string; label: string },
    false,
    GroupBase<{ value: string; label: string }>
>;
type IOptionState = OptionProps<
    {
        value: string;
        label: string;
        disabled?: boolean;
    },
    false,
    GroupBase<{ value: string; label: string; disabled?: boolean }>
>;

export const customStyles = (withoutBorder?: boolean, multiColor?: boolean, menuLeft?: boolean) => {
    return {
        control: (baseStyles: IStyle, state: IControlState) => ({
            height: '100%',
            display: 'flex',
            border: withoutBorder ? 'none' : '1px solid',
            borderColor: state.menuIsOpen ? '#7EA4E7' : '#BCBCBC',
            backgroundColor: '#FBFAF9',
            borderBottom: state.menuIsOpen ? 'none' : withoutBorder ? 'none' : '1px solid #BCBCBC',
            borderRadius: '4px',
            borderBottomLeftRadius: state.menuIsOpen ? '0px' : '4px',
            borderBottomRightRadius: state.menuIsOpen ? '0px' : '4px',
        }),
        valueContainer: (baseStyles: IStyle) => ({
            ...baseStyles,
            fontSize: '14px',
            fontWeight: '400',
            padding: withoutBorder ? '0' : '0 10px',
            justifyContent: withoutBorder ? 'flex-end' : 'flex-start',
        }),
        singleValue: (baseStyles: IStyle) => ({
            ...baseStyles,
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            color: 'hsl(0, 0%, 20%) !important',
            margin: '0',
        }),
        input: (baseStyles: IStyle) => ({
            ...baseStyles,
            margin: '0',
            padding: '0',
            cursor: 'text',
        }),
        indicatorsContainer: (baseStyles: IStyle) => ({
            ...baseStyles,
            padding: withoutBorder ? '0' : '3px',
            cursor: 'pointer',
        }),
        indicatorSeparator: () => ({}),
        menu: (baseStyles: IStyle) => ({
            ...baseStyles,
            minWidth: withoutBorder ? 'max-content' : 'initial',
            boxShadow: withoutBorder ? '0px 2px 14px #DBE0EA' : 'none',
            border: withoutBorder ? 'none' : '1px solid #7EA4E7',
            borderTop: 'none',
            fontSize: '14px',
            fontWeight: '400',
            top: '24px',
            ...(() => (menuLeft ? { left: '0' } : { right: '0' }))(),
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            backgroundColor: '#FBFAF9',
            zIndex: '99',
        }),
        menuList: (baseStyles: IStyle) => ({
            ...baseStyles,
        }),
        option: (baseStyles: IStyle, state: IOptionState) => ({
            ...baseStyles,
            display: 'flex',
            alignItems: 'center',
            color: '#3D4044',
            backgroundColor: state.isSelected
                ? '#dfe4ec'
                : state.isFocused
                ? 'rgba(223, 228, 236, 0.5)'
                : 'inherit',
            ':active': {
                backgroundColor: 'rgba(223, 228, 236, 0.5)',
            },
        }),
        multiValue: (baseStyles: IStyle) => ({
            ...baseStyles,
            backgroundColor: multiColor ? 'transparent' : '#253F4B',
            margin: '2px',
            borderRadius: '4px',
            height: '24px',
        }),
        multiValueLabel: (baseStyles: IStyle) => ({
            ...baseStyles,
            color: '#FBFAF9',
            borderRadius: '4px',
            lineHeight: '18px',
            padding: '3px 6px',
        }),
        multiValueRemove: (baseStyles: IStyle) => ({
            ...baseStyles,
            color: 'rgba(255, 255, 255, 0.5)',
            borderRadius: '4px',
            position: 'relative',
            left: '-6px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            ':hover': {
                backgroundColor: 'none',
                color: '#FBFAF9',
            },
        }),
        dropdownIndicator: (baseStyles: IStyle) => ({
            ...baseStyles,
            padding: withoutBorder ? '8px 0 8px 8px' : '8px',
        }),
    };
};
