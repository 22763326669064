import { apiCreateSprint, apiGetSprint, apiUpdateSprint } from '~/api/project';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetTaskList } from '~/api/task';
import { defaultOptions, TASKS_FILTERS } from '~/utils/tasks';

const createSprint = createAsyncThunk(
    'sprint/add',
    async (sprintData: { project_id: number; title: string }) => {
        return await apiCreateSprint(sprintData);
    },
);

const getSprint = createAsyncThunk('sprint/get', async (id: number) => {
    return await apiGetSprint(id);
});

const updateSprint = createAsyncThunk(
    'sprint/update',
    async (sprint: { id: number; data: { [p: string]: any } }) => {
        return await apiUpdateSprint(sprint.id, sprint.data);
    },
);

const loadBacklogIds = createAsyncThunk(
    'sprint/loadBacklog',
    async (data: { offset: number; projectId: number; sprintId: string }) => {
        return await apiGetTaskList(data.offset, {
            ...defaultOptions,
            filters: {
                ...defaultOptions.filters,
                [TASKS_FILTERS.SPRINT_ID]: data.sprintId,
                [TASKS_FILTERS.STATUS]: 'open%26backlog',
                [TASKS_FILTERS.PID]: `${data.projectId}`,
            },
        });
    },
);

export { createSprint, getSprint, updateSprint, loadBacklogIds };
