import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    apiCreateWikiPage,
    apiDeleteWikiPage,
    apiGetWiki,
    apiGetWikiPage,
    apiUpdateWikiPage,
} from '~/api/project';
import { apiDeleteFilesFromWikiPage, apiUploadFilesToWikiPage } from '~/api/task';

const removePage = createAsyncThunk('wiki/delete', async (id: number) => {
    return await apiDeleteWikiPage(id);
});

const loadPage = createAsyncThunk('wiki/add', async (id: number) => {
    return await apiGetWikiPage(id);
});

const getWiki = createAsyncThunk('wiki/addWiki', async (id: number) => {
    return await apiGetWiki(id);
});

const createPage = createAsyncThunk(
    'wiki/create',
    async (data: {
        project_id: number;
        parent_id: number;
        order: number;
        content?: string;
        title?: string;
        slug?: string;
    }) => {
        return await apiCreateWikiPage(data);
    },
);

const updatePage = createAsyncThunk(
    'wiki/update',
    async (data: { id: number; data: { title?: string; content?: string } }) => {
        return await apiUpdateWikiPage(data.id, data.data);
    },
);

const uploadFilesToWikiPage = createAsyncThunk(
    'wiki/uploadFilesToWikiPage',
    async (data: { projectId: number; pageId: number; files: FormData }) => {
        return await apiUploadFilesToWikiPage(data.projectId, data.pageId, data.files);
    },
);

const deleteFilesFromWikiPage = createAsyncThunk(
    'wiki/deleteFilesFromWikiPage',
    async (data: { projectId: number; pageId: number; fileId: number }) => {
        return await apiDeleteFilesFromWikiPage(data.projectId, data.pageId, data.fileId);
    },
);

export {
    removePage,
    loadPage,
    getWiki,
    createPage,
    updatePage,
    uploadFilesToWikiPage,
    deleteFilesFromWikiPage,
};
