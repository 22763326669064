import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ORDER_BY } from '~/const';
import { getProjectList, updateProjectListOptions } from '~/store/slices/projectList/reducers';

export const projectsAdapter = createEntityAdapter<IProject>();

export interface IProjectListState {
    loadedIds: number[];
    totalCount: number;
    isLoading: boolean;
    isOptionsLoading: boolean;
    isMoreLoading: boolean;
    config: ITableConfig[];
    options: IMeta;
    offset: number;
}

const defaultOptions = {
    filters: {},
    orderby: ['title', ORDER_BY.ASC],
    filterName: '',
};

const initialState: IProjectListState = {
    loadedIds: [],
    totalCount: 0,
    isLoading: false,
    isOptionsLoading: false,
    isMoreLoading: false,
    config: [],
    options: {
        filters: {},
        orderby: ['title', ORDER_BY.ASC],
        filterName: '',
    },
    offset: 0,
};

export const projectListSlice = createSlice({
    name: 'projectList',
    initialState,
    reducers: {
        changeOffset(state, action) {
            state.offset = action.payload;
        },
        changeTotalCount(state, action) {
            state.totalCount = action.payload;
        },
        setProjectListOptions(state, action) {
            state.options = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectList.pending, (state, action) => {
            if (action.meta.arg.offset === 0) {
                state.isLoading = true;
            } else {
                state.isMoreLoading = true;
            }
        });
        builder.addCase(getProjectList.fulfilled, (state, action) => {
            if (action.payload) {
                state.totalCount = action.payload.totalCount;
                state.isLoading = false;
                state.isMoreLoading = false;
                if (action.meta.arg.offset === 0) {
                    state.loadedIds = [...action.payload.rows.map((item: ITask) => item.id)];
                } else {
                    state.loadedIds = [
                        ...state.loadedIds,
                        ...action.payload.rows.map((item: ITask) => item.id),
                    ];
                }
                state.offset = action.meta.arg.offset;
            }
        });
        builder.addCase(updateProjectListOptions.fulfilled, (state, action) => {
            state.options = action.meta.arg;
        });
    },
    selectors: {
        selectProjectsTotalCount: (sliceState) => sliceState.totalCount,
        selectProjectListIsLoading: (sliceState) => sliceState.isLoading,
        selectProjectIsMoreLoading: (sliceState) => sliceState.isMoreLoading,
        selectProjectListOffset: (sliceState) => sliceState.offset,
        selectProjectListIds: (sliceState) => [...sliceState.loadedIds],
        selectProjectListOptions: (sliceState) => sliceState.options ?? defaultOptions,
        selectProjectListOptionsIsLoading: (sliceState) => sliceState.isOptionsLoading,
    },
});

export type taskListSlice = {
    [projectListSlice.name]: ReturnType<typeof projectListSlice['reducer']>;
};
export const { changeTotalCount, changeOffset, setProjectListOptions } = projectListSlice.actions;
export const {
    selectProjectsTotalCount,
    selectProjectListIsLoading,
    selectProjectIsMoreLoading,
    selectProjectListOffset,
    selectProjectListIds,
    selectProjectListOptions,
    selectProjectListOptionsIsLoading,
} = projectListSlice.selectors;
