import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    createGroup,
    deleteGroup,
    getGroup,
    kickFromGroup,
    refuseGroupInvite,
} from '~/store/slices/groups/reducers';
import { getAccount } from '~/store/slices/account/reducers';

export const groupsAdapter = createEntityAdapter<IGroup>();

export interface IGroupState {
    entities: Record<number, IGroup[]>;
}

export const groupsSlice = createSlice({
    name: 'groups',
    initialState: {
        ...groupsAdapter.getInitialState(),
    },
    reducers: {
        create: groupsAdapter.setMany,
        add: groupsAdapter.addMany,
        update: groupsAdapter.upsertMany,
        remove: groupsAdapter.removeMany,
        clear: groupsAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(createGroup.fulfilled, (state, action) => {
            if (action.payload) {
                groupsAdapter.upsertOne(state, action.payload.group);
            }
        });
        builder.addCase(getAccount.fulfilled, (state, action) => {
            if (action.payload) {
                groupsAdapter.upsertMany(state, action.payload.groups);
            }
        });
        builder.addCase(refuseGroupInvite.fulfilled, (state, action) => {
            if (action.meta.arg) {
                groupsAdapter.removeOne(state, action.meta.arg);
            }
        });
        builder.addCase(kickFromGroup.fulfilled, (state, action) => {
            if (action.meta.arg) {
                groupsAdapter.removeOne(state, action.meta.arg.groupId);
            }
        });
        builder.addCase(getGroup.fulfilled, (state, action) => {
            if (action.payload) {
                groupsAdapter.upsertOne(state, action.payload.group);
            }
        });
        builder.addCase(deleteGroup.fulfilled, (state, action) => {
            if (action.meta.arg) {
                groupsAdapter.removeOne(state, action.meta.arg);
            }
        });
    },
    selectors: {
        selectGroups: (sliceState) => Object.values(sliceState.entities),
        selectGroup: (sliceState, id: number) => {
            return sliceState.entities[id];
        },
    },
});

export type groupsSlice = {
    [groupsSlice.name]: ReturnType<typeof groupsSlice['reducer']>;
};
export const { create, update, remove, clear, add } = groupsSlice.actions;
export const { selectGroups, selectGroup } = groupsSlice.selectors;
