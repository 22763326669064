import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    addTaskTimings,
    approveTiming,
    cancelTiming,
    getTaskTimings,
    updateTaskTiming,
} from '~/store/slices/task/reducers';
import { tasksAdapter } from '~/store/slices/task/slice';

export const timingAdapter = createEntityAdapter<ITiming>();

export interface ITimingState {
    entities: Record<number, ITiming[]>;
}

export const timingSlice = createSlice({
    name: 'timing',
    initialState: {
        ...timingAdapter.getInitialState(),
    },
    reducers: {
        create: timingAdapter.setMany,
        add: timingAdapter.addMany,
        update: timingAdapter.upsertMany,
        remove: timingAdapter.removeMany,
        clear: timingAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(addTaskTimings.fulfilled, (state, action) => {
            if (action.payload) {
                action.payload.timings.map((timing: ITiming) => {
                    timingAdapter.upsertOne(state, { ...timing });
                });
            }
        });
        builder.addCase(updateTaskTiming.fulfilled, (state, action) => {
            const timing = state.entities[action.meta.arg.timingId];
            timingAdapter.upsertOne(state, {
                ...timing,
                request: action.payload.timing_change_request,
            });
        });
        builder.addCase(approveTiming.fulfilled, (state, action) => {
            const timing = state.entities[action.meta.arg.timingId];
            timingAdapter.upsertOne(state, {
                ...timing,
                stime: timing.request?.stime ?? '',
                ftime: timing.request?.ftime ?? '',
                request: null,
            });
        });
        builder.addCase(cancelTiming.fulfilled, (state, action) => {
            const timing = state.entities[action.meta.arg.timingId];
            timingAdapter.upsertOne(state, {
                ...timing,
                is_edited: true,
                request: null,
            });
        });
        builder.addCase(getTaskTimings.fulfilled, (state, action) => {
            timingAdapter.upsertMany(state, action.payload.timings);
        });
    },
    selectors: {
        selectTimings: (sliceState) => sliceState.entities,
        selectTiming: (sliceState, id: number) => sliceState.entities[id],
        selectTaskTimings: (sliceState, taskId: number) =>
            Object.values(sliceState.entities).filter((timing) => timing.taskid === taskId),
        selectTimingsArray: (sliceState) => sliceState && Object.values(sliceState.entities),
    },
});

export type timingSlice = {
    [timingSlice.name]: ReturnType<typeof timingSlice['reducer']>;
};
export const { create, update, remove, clear, add } = timingSlice.actions;
export const { selectTimings, selectTiming, selectTimingsArray, selectTaskTimings } =
    timingSlice.selectors;
