import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { useAppSelector } from '~/store';
import { selectAccount } from '~/store/slices/account/slice';
const PrivateRoutes = () => {
    const account = useAppSelector((state) => selectAccount(state));
    const location = useLocation();

    return account?.id ? (
        !account?.is_email_valid ? (
            <Navigate to={urlsMap.emailConfirm} />
        ) : (
            <Outlet />
        )
    ) : (
        <Navigate to={urlsMap.index} state={{ prevState: location.pathname }} />
    );
};

export default PrivateRoutes;
