import { _delete, get, post, put } from '~/utils/api';
import store, { useAppSelector } from '~/store';
import { META } from '~/const';
import { selectAppMeta } from '~/store/slices/app/slice';

export const apiGetProjectList = async (offset: number, options: IMeta, groupId?: number) => {
    return await get(
        `projects?limit=20&offset=${offset}&filters=${JSON.stringify(
            options?.filters,
        )}&orderby=${JSON.stringify(options?.orderby)}${groupId ? '&group_id=' + groupId : ''}`,
    );
};

export const apiGetProject = async (id: number) => await get(`projects/${id}`);

export const apiGetProjectTags = async (id: number) => await get(`projects/${id || 0}/tags`);

export const apiCreateProject = async (project: { group_id: string; title: string }) =>
    await post('projects', project);

export const apiUpdateProject = async (id: number, data: any) => await put(`projects/${id}`, data);

export const apiGetSprint = async (id: number) => await get(`sprints/${id}`);

export const apiUpdateSprint = async (id: number, data: any) => await put(`sprints/${id}`, data);

export const apiCreateSprint = async (data: { project_id: number; title: string }) =>
    await post('sprints', data);

export const apiGetWiki = async (id: number) => await get(`wiki/${id}`);

export const apiGetWikiPage = async (id: number) => await get(`wiki/page/${id}`);

export const apiUpdateWikiPage = async (id: number, data: any) =>
    await put(`wiki/page/${id}`, data);

export const apiCreateWikiPage = async (data: any) => await post('wiki/page', data);

export const apiDeleteWikiPage = async (pageId: number) => await _delete(`wiki/page/${pageId}`);

export const apiDeleteProject = (id: number) => _delete(`projects/${id}`);

export const apiArchiveProject = (id: number) => put(`projects/${id}/archive`);

export const apiUnarchiveProject = (id: number) => put(`projects/${id}/unarchive`);

export const apiGetProjectInvites = (id: number) => get(`projects/${id}/invites`);

export const apiKickInvite = (projectId: number, inviteId: number) =>
    _delete(`projects/${projectId}/invites/${inviteId}`);

export const apiProjectInvite = (data: { invited: string[] }, projectId: number) =>
    post(`projects/${projectId}/invites`, data);

export const apiAcceptInvite = async (projectId: number) =>
    await put(`projects/${projectId}/invites/accept`);

export const apiRefuseInvite = async (projectId: number) =>
    await put(`projects/${projectId}/invites/refuse`);

export const apiCreateStatus = async (projectId: number, data: any) =>
    await post(`projects/${projectId}/status`, data);

export const apiUpdateStatus = async (projectId: number, statusId: number, data: any) =>
    await put(`projects/${projectId}/status/${statusId}`, data);

export const apiDeleteStatus = async (projectId: number, statusId: number) =>
    await _delete(`projects/${projectId}/status/${statusId}`);

export const apiUpdateStatusOrder = async (projectId: number, data: number[]) =>
    await put(`projects/${projectId}/status/order`, data);

export const apiUpdateProjectRole = async (projectId: number, userId: number, data: any) =>
    await put(`projects/${projectId}/users/${userId}/role`, data);

export const apiUpdateWikiOrder = async (data: any) => await post('wiki/page/order', data);
