import * as React from 'react';
import styles from './TaskCards.module.scss';
import Avatar from '~/components/User/Avatar';
import { getColor, localDate, prepareTimeFromMinutes } from '~/utils/utils';
import Priority from '~/components/Icons/Priority/Priority';
import Status from '~/components/Icons/Status/Status';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import { PLACE } from '~/const';
import { useAppSelector } from '~/store';
import { selectUser } from '~/store/slices/user/slice';
import { selectSprint } from '~/store/slices/sprint/slice';
import { selectTask } from '~/store/slices/task/slice';

interface IFC {
    taskId: number;
    className?: string;
    withoutStatus?: boolean;
    hideSprint?: boolean;
}

const TaskCard = ({ taskId, className, withoutStatus, hideSprint }: IFC) => {
    const task = useAppSelector((state) => selectTask(state, taskId));
    const sprint = useAppSelector((state) => selectSprint(state, task?.sprint_id ?? 0));

    if (!task) {
        return null;
    }

    const executive = useAppSelector((state) => selectUser(state, task?.executive ?? 0));

    return (
        <div
            className={classNames(styles.wrapper, className, {
                [styles.isDelayed]: task.isDelayed,
                [styles.hasChild]: task.has_subtasks,
            })}
        >
            <div className={styles.secondColumn}>
                {!hideSprint && !!task?.sprint_id && (
                    <Link
                        to={`${urlsMap.projectList}/${task.project}${urlsMap.processList}/${task.sprint_id}`}
                        className={styles.sprint}
                    >
                        {sprint?.title}
                    </Link>
                )}
                <div className={styles.text}>
                    {!withoutStatus && (
                        <Status
                            statusId={task.status}
                            projectId={task.pid}
                            className={styles.status}
                            classNameTooltip={styles.tooltipStatus}
                            place={PLACE.RIGHT}
                        />
                    )}
                    <Link
                        to={`${urlsMap.taskList}/${task.id}`}
                        className={classNames(styles.title, {
                            [styles.withoutStatus]: !withoutStatus,
                        })}
                    >
                        <span>#{task.id}</span> {task.title}
                    </Link>
                </div>
                {task?.tags && !!task?.tags?.length && (
                    <div className={styles.tags}>
                        {task?.tags &&
                            task.tags.map((tag) => (
                                <div className={classNames(styles.tag, getColor(tag.name))}>
                                    {tag.name}
                                </div>
                            ))}
                    </div>
                )}
            </div>
            <div className={styles.firstColumn}>
                <div className={styles.details}>
                    {task.edate && (
                        <div
                            className={classNames(styles.date, {
                                [styles.delayed]: task.isDelayed,
                            })}
                        >
                            {task.edate && localDate(task.edate)}
                        </div>
                    )}
                    {!!task.label?.label_color && (
                        <div
                            className={classNames(styles.tag, styles.label)}
                            style={{ backgroundColor: '#' + task.label.label_color }}
                        >
                            {task.label.label}
                        </div>
                    )}
                    {!!task.has_subtasks && (
                        <div
                            className={classNames(styles.tag, styles.tagCompletion, {
                                [styles.red]: task.completion ? task.completion < 100 : true,
                            })}
                        >
                            {Math.floor(task.completion || 0)}%
                        </div>
                    )}
                </div>
                <div className={styles.details}>
                    <div className={styles.difficulty}>
                        {prepareTimeFromMinutes(task.difficulty)}
                    </div>
                    <Priority
                        priority={task.priority}
                        className={styles.priority}
                        withZero={true}
                    />
                    <Avatar
                        className={styles.avatar}
                        avatar={{ avatar: executive?.avatar, name: executive?.name }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TaskCard;
